import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Phonecall_1.png';
import PhoneCall from '../../../assets/images/common-images/phoneCall.png';
import Messenger from '../../../assets/images/common-images/messenger.png';
import Viber from '../../../assets/images/common-images/viber.png'
import { io } from "socket.io-client";
import Prescription from '../../../assets/images/common-images/prescriptions.png';
import Orderlist from '../../../assets/images/common-images/orderlist.png';
import PrescriptionGray from '../../../assets/images/common-images/prescriptionsgray.png';
import OrderlistGray from '../../../assets/images/common-images/orderlistgray.png';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import {formatNumberWithComma} from '../../../utils/utils';

import {
  getCart, registerRx, checkPhoneNumber, login, getCCAgents, getDrugstore, getDrugstoreRx, getDiscountTypesPerDrugstoreRx, getDeliveryTypes,
  getUserProfile, uploadBought, sendSocketCommand, startCall, endCall, uploadMedia
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { borderRadius } from '@mui/system';
import MyButton from '../../../Components/Common/MyButton';
import packageJson from '../../../../package.json';

import { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';
import Api from '../../../services/Api';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const Tumawag = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [level, setLevel] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState();
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [showPreloader, setShowPreloader] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [showText, setShowText] = useState(false);
  const [textToShow, setTextToShow] = useState("");
  const [subTextToShow, setSubTextToShow] = useState("");
  const [dsData, setDsData] = useState([]);
  const [storeItemId, setStoreItemId] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showNewCart, setShowNewCart] = useState(false);
  const [sku, setSku] = useState([]);
  const [origSku, setOrigSku] = useState([]);
  const [shownDiscount, setShownDiscount] = useState(false);
  const [selectedDs, setSelectedDs] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState([]);
  const [showFinalCost, setShowFinalCost] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [deliveryId, setDeliveryId] = useState(0);
  const [userData, setUserData] = useState();
  const [alreadyBought, setAlreadyBought] = useState(false);
  const [showMayWe, setShowMayWe] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const hiddenFileInputReseta = React.useRef(null);
  const hiddenFileInputOrderlist = React.useRef(null);
  const [enableCapture, setEnableCapture] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showDownload2, setShowDownload2] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [fileReseta, setFileReseta] = useState();
  const [fileOrderlist, setFileOrderlist] = useState();

  const props = useLocation().state;

  const apiKey = '47621101';
  const sessionId = '2_MX40NzYyMTEwMX5-MTY3MDIyMjUzNjA4Mn41NzllREdiWEduOWRTc291ZkhiaWFFR01-fg';
  const token = 'T1==cGFydG5lcl9pZD00NzYyMTEwMSZzaWc9OTY3Zjc0NjUwMDU0MTU0MDNmODJhZmQ3MTk1Y2ZiN2QwZGJiYjQyNjpzZXNzaW9uX2lkPTJfTVg0ME56WXlNVEV3TVg1LU1UWTNNREl5TWpVek5qQTRNbjQxTnpsbFJFZGlXRWR1T1dSVGMyOTFaa2hpYVdGRlIwMS1mZyZjcmVhdGVfdGltZT0xNjcwMjIyNTQ1Jm5vbmNlPTAuNDU1NTI1NDExMTg5NDg3MyZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNjcyODE0NTQ0JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9';
  // const sessionId = '2_MX40NzYyMTEwMX5-MTY3MDI5MTc1MTY5OX54MUk5bVZXUER1elpLSExvRTdlSjI0N3d-fg';
  // const token = 'T1==cGFydG5lcl9pZD00NzYyMTEwMSZzaWc9NjM2ZWVlZThkMjJmZTNhODJiNzZlMmMxZDkzYTFjZDE0M2E1YTBiYTpzZXNzaW9uX2lkPTJfTVg0ME56WXlNVEV3TVg1LU1UWTNNREk1TVRjMU1UWTVPWDU0TVVrNWJWWlhVRVIxZWxwTFNFeHZSVGRsU2pJME4zZC1mZyZjcmVhdGVfdGltZT0xNjcwMjkxNzc5Jm5vbmNlPTAuNDY4MzAzMDQ0OTAwNDE2MiZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNjcyODgzNzc5JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9';

  useEffect(async () => {
    if (typeof userId !== 'undefined') {
      console.log(`userId=${userId}; selectedData=${JSON.stringify(selectedData)}`)
      _startCall(selectedData.id)
      _onSendSocket(selectedData.id, "memberCalling", 0);

      const response1 = await getDrugstore();
      if (response1) {
        // console.log(`response1=${JSON.stringify(response1)}`)
        setDsData(response1.data.data);
      }

      const response2 = await getDiscountTypesPerDrugstoreRx();
      if (response2) {
        // console.log(`response2=${JSON.stringify(response2)}`)
        setDiscountTypes(response2.data);
      }
      
      const response3 = await getDeliveryTypes();
      if (response3) {
        // console.log(`response3=${JSON.stringify(response3)}`)
        const sDeliveryType = response3.data.data.filter(item => item.isSelected)[0];
        setDeliveryTypes(response3.data.data);
        setDeliveryFee(sDeliveryType.price);
        setSelectedDeliveryType(sDeliveryType);
      }
    }
  }, [userId]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await getCCAgents();
      if (response.data.length > 0) {
        setSelectedData(response.data[0]);
        setDataLoaded(true);
        const tempCode = randomString();
        setCode(tempCode);
      }
      else {
        setDataLoaded(true);
      }
    }

    // call the function
    fetchData()
    // make sure to catch any error
    .catch(console.error);

    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      console.log('hello')
      _endCall();
    });
  }, [])

  useEffect(() => {
    console.log(`2. code=${code}`)
    if (code !== "") {
      register(code);
    }
    // checkPhone(`+639178031010`);
  }, [code])

  useEffect(() => {
    if (storeItemId > 0) {
      console.log(`storeItemId=${storeItemId}`)
      let storeItem = dsData.filter(item => item.id == 3)[0].details.find(i => i.brand.id == Number(storeItemId))
      console.log(`storeItem=${JSON.stringify(storeItem)}`)
      if (storeItem) {
        setTextToShow(`${storeItem.brand.name} ${storeItem.dosage.name} (${storeItem.generic.name})`);
        setSubTextToShow(`₱${formatNumberWithComma(storeItem.brand.price,2)}`);
        setShowText(true); 
        setShowNewCart(false);
        setShowUserDetails(false);
        setShowDiscount(false);
        setShowFinalCost(false);
        setShowConfirm(false);
        setShowMayWe(false);
        setShowImage(false);
      }
    }
  }, [storeItemId])

  useEffect(() => {
    if (deliveryId > 0) {
      const selected = deliveryTypes.filter(item => item.id == deliveryId)[0]
      // console.log(`selected=${JSON.stringify(selected)}`)

      setDeliveryFee(selected.price);
      setSelectedDeliveryType(selected);
      setTextToShow(`DELIVERY FEE: (${selected.name})`);
      setSubTextToShow(`₱${formatNumberWithComma(selected.price,2)}`);
      setShowText(true);
      setShowUserDetails(false);
      setShowDiscount(false);
    }
  }, [deliveryId])

  const _getCart = async (userId) => {
    let response = await getCart({userId});
    if (response) {
      console.log(`5. getCart.response=${JSON.stringify(response)}; userId=${userId}`)
      const origSKU = response.data.data;
      // console.log(`_getCart=${JSON.stringify(origSKU)}`)
      setSku([...origSKU]);
      setOrigSku(origSKU);
      setShowText(false);
      setShowMayWe(false);
      setShowNewCart(true);
      setShowDiscount(false);
      setShowConfirm(false);
      if (response.data.data.length == 0) {
        setShowMayWe(true);
      }
    }
  }

  useEffect(() => {
    // console.log(`sku`)
    if (sku.length > 0) {
      _onDrugstorePress(false);
    }
  }, [sku])

  useEffect(() => {
    console.log('there')
    if (userData) {
      console.log('here')
      setShowUserDetails(true);
      setShowText(false);
      setShowNewCart(false);
      setShowDiscount(false);
      setShowFinalCost(false);
      setShowConfirm(false);
      _onDrugstorePress(false);
    }
  }, [userData])

  const _getUserProfile = async (phoneNumber) => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(phoneNumber)});
    if (response) {
      if (response.data.data) {
        console.log(`userId=${response.data.data.id}`)
        if (typeof response.data.data.id !== 'undefined') {
          const response1 = await getUserProfile({id: response.data.data.id});
          if (response1) {
            console.log(`userData=${JSON.stringify(response1.data.data)}`)
            setUserData(response1.data.data);
            const response2 = await getDiscountTypesPerDrugstoreRx();
            if (response2) {
              // console.log(`response2=${JSON.stringify(response2)}`)
              setDiscountTypes(response2.data);
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (showDiscount) {
      console.log('here')
      setShownDiscount(true);
    }
  }, [showDiscount])

  useEffect(() => {
    console.log(`showConfirm=${showConfirm}; alreadyBought=${alreadyBought}`)
    if (showConfirm && !alreadyBought) {
      _buy();
    }
  }, [showConfirm])

  const _onSendSocket = (userId, command, data) => {
    console.log(`command=${command}, data=${data}`)
    const params = data ?  {
      userId: userId,
      command: command,
      data: data
    } : { 
      userId: userId,
      command: command 
    }
    
    sendSocketCommand(params)
    .then(({data}) => {
      
    })
    .catch(e => {
      console.warn('send socket ERROR', e.message);
    });
  }

  const _startCall = (agentId) => {
    startCall({agentId})
    .then(({data}) => {
        
    })
    .catch(e => {
        console.warn('start call ERROR', e.message);
    });
  }

  const _buy = async () => {
    const selected = selectedDs.details;
    console.log(`userData=${JSON.stringify(userData)}`)
    const param = {
      userId: userData.userId,
      total: totalPrice,
      eta: new Date(sku[0].eta).toISOString().slice(0,-5), 
      deliveryFee: Number(deliveryFee),
      taxes: 0, //Number((totalPrice - totalDiscount) * taxPercentage), 
      address: userData.address,
      totalDiscount: totalDiscount,
      drugstoreId: selectedDs.id,
      details: selected.map((item) => {
        return {
          genericsId: item.generic.id,
          brandedId: item.brand.id,
          dosageId: item.dosage.id,
          quantity: item.quantity,
          price: item.brand.price
        }
      }),
      discounts: discountTypesData.filter(item => item.amount > 0).map((item) => {
        return {
          discountTypeId: item.id,
          isOneTime: item.is_one_time,
          isPercentage: item.is_percentage,
          isSeniorPwd: item.is_senior_pwd,
          amount: item.amount,
          value: item.value,
          discountValue: item.discountValue,
          subTotal: item.subTotal,
          sortOrder: item.sort_order
        }
      })
    }

    uploadBought(param)
    .then((data) => {
      setShowFinalCost(false);
      setAlreadyBought(true);
    })
    .catch(e => {
        console.warn('upload bought ERROR', e.message);
    });
  }

  const loginHandler = async (oldId, apiKey) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        // localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        // localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", "1");
        localStorage.setItem("autoJoin", "0");

        const user = response.headers.authorization;
        if (user !== null) {
          console.log(`new header`)
          Api.setAuthHeader(response.headers.authorization);
        }
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const checkPhone = async (phoneNumber) => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(phoneNumber)});
    if (response) {
      if (response.data.data) {
        console.log(`4. checkPhone.response=${JSON.stringify(response.data.data)}`)
        await loginHandler(response.data.data.oldId, response.data.data.apiKey)
        // _getCart(response.data.data.id);
        setUserId(response.data.data.id);
        socket.emit("online", { userId: response.data.data.id });
        // socket.on("newCart", (data) => {
        //   console.log(`new cart`)  
        // })
        socket.on("addMedicine", (data) => {
          console.log(`addMedicine; data=${data}`)
          setStoreItemId(Number(data))
        });

        socket.on("newCart", (data) => {
          console.log(`newCart; data=${data}`)
          _getCart(userId);
        })
        socket.on("setDelivery", (data) => {
          console.log(`setDelivery; data=${data}`)
          setDeliveryId(Number(data));
        })
        socket.on("updateUserDetails", (data) => {
          console.log(`updateUserDetails; data=${data}`)
          setTimeout(() => {
            _getUserProfile(data);
          }, 1000)
        })
        socket.on("showDiscount", (data) => {
          console.log(`showDiscount; data=${data}`)
          setShowDiscount(true);
          setShowUserDetails(false);
          setShowText(false);
          setShowNewCart(false);
          setShowFinalCost(false);
          setShowConfirm(false);
        })
        socket.on("finalCart", (data) => {
          console.log('finalCart')
          setShowFinalCost(false);       
          setShowFinalCost(true);            
        })
        socket.on("confirmOrder", (data) => {
          console.log('confirmOrder');
          setShowConfirm(true);
        })
        socket.on("showDeliverySms", (data) => {
          console.log("showDeliverySms");
          setShowConfirm(false);
          setShowSms(true);
          setShowDownload(false);
          setShowMayWe(false);
          setShowText(false);
        })
        socket.on("endCall", (data) => {
          console.log("endCall");
          _endCall();
        })
        socket.on("enableCapture", (data) => {
          console.log("enableCapture");
          setEnableCapture(true)
        })
        socket.on("showDownloadApp", (data) => {
          console.log("showDownloadApp");
          setEnableCapture(false);
          setShowMayWe(false);
          setShowSms(false);
          setShowText(false);
          setShowDownload(true);
        })
      }
    }
  }

  useEffect(() => {
    if (showDownload) {
      const timer = setTimeout(() => {
        console.log('This will run after 5 second!')
        setShowDownload2(true);
        setShowDownload(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showDownload]);

  useEffect(() => {
    if (showFinalCost) {
      _onDrugstorePress(true);
    }
  }, [showFinalCost])

  const register = async (tempCode) => {
    console.log(`3. register: tempCode=${tempCode}; selectedData=${JSON.stringify(selectedData)}`)
    const response = await registerRx({
      firstname: tempCode,
      lastname: tempCode,
      displayName: `TEMP ${tempCode} ${tempCode}`,
      phoneNumber: `+${tempCode}`,
      address: tempCode,
      birthDate: `1985-01-01`,
      countryCode: 'PH',
      ccAgentId: selectedData.id
    });
    console.log(`register.response=${JSON.stringify(response)}`)
    if (response) {
      // console.log(`there`)
      checkPhone(`+${tempCode}`);
    }    
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    // console.log(`userId=${userId}`)
    const interval = setInterval(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1)
      }
      else {
        clearInterval(interval);
        setShowPreloader(false);
      }
    }, 30);
    return () => clearInterval(interval);
  }, [percentage]);

  const randomString = () => {
    const length = 4;
    const chars = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const w = 300;
  const h = 470;

  const renderItem = (item, index) => {
    // console.log('here')
    const maxText = 28;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.62}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.32, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  const _onDrugstorePress = async (showFinalCost) => {
    const response = await getDrugstoreRx();
    if (response) {
      if (response.data.data.length > 0) {
        setDsData(response.data.data);
        let data = [];
        // console.log(`dsData=${JSON.stringify(response.data.data)}`)
        // console.log(`sku=${JSON.stringify(sku)}`)
        response.data.data.map(store => {
          let total = 0;
          let details = [];      
          sku.filter(item => item.quantity > 0).map(selected => {
            // const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
            // const storeItem = this.state.isGeneric ? store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id) : store.details.find(i => i.brand.id == selected.brand.id)
            const storeItem = store.details.find(i => i.brand.id == selected.brand.id)
            if (storeItem) {
              total += storeItem.brand.price * selected.quantity;
              details.push({
                ...storeItem,
                quantity: selected.quantity
              })
            }
          })
          data.push({
            ...store,
            total,
            details: [...details]
          })
        })
        ///// buy now
        const selected = data.filter(item => !item.hasBorder)
        console.log(`selected=${JSON.stringify(selected)}`)
        if (selected.length > 0) {
          _onDsRowPress(selected[0], showFinalCost);
        }   
      }
    } 
  }

  const _onDsRowPress = (rowData, showFinalCost) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    // console.log(`discountTypes=${JSON.stringify(discountTypes)}`)
    const totalPriceHere = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
    // console.log(`totalPriceHere=${totalPriceHere}`)
    let totalDiscountHere = 0;
    const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPriceHere >= item.volume)
      .sort((a, b) => a.volume < b.volume ? 1 : -1)
    let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    // console.log(`tempDT3=${JSON.stringify(tempDT3)}`)
    const tempDT = tempDT3.map(item => {
      if (rowData.discount == 0 && item.id == 1) { //no member discount
        //force amount to 0 for later filtering
        return {
          ...item,
          amount: 0
        }
      }
      else {
        if (item.is_percentage == 1 && totalPriceHere > totalDiscountHere + (totalPriceHere - totalDiscountHere) * item.value) { //percentage
          const seniorDiscount = (totalPriceHere - totalDiscountHere) * item.value;
          totalDiscountHere += seniorDiscount;
          return {
            ...item,
            discountValue: seniorDiscount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else if (item.is_percentage == 0 && totalPriceHere > totalDiscountHere + item.amount) {
          totalDiscountHere += item.amount;
          return {
            ...item,
            discountValue: item.amount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else {
          return {
            ...item,
            isUsed: false
          }
        }
      }
    }).filter(item => item.isUsed);
    
    // console.log(`tempDT=${JSON.stringify(tempDT)}; totalDiscount=${totalDiscountHere}`)
    console.log(`shownDiscount=${shownDiscount}; showFinalCost=${showFinalCost}`)
    if (shownDiscount) {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(true);
      setShowDiscount(false);
      setShowNewCart(false);
    }
    else {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(false);
    }
  }

  const _endCall = () => {
    window.open("about:blank", "_self");
    window.close();
    endCall({})
    .then(({data}) => {
      _onSendSocket(selectedData.id, "memberEndCall", null);
    })
    .catch(e => {
      console.warn('end call ERROR', e.message);
    });
  }

  const handleFile = async (file, isReseta) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      // const upload = await updateAvatar({avatarBig: response.data.data[0].imageUrlBigUrl, avatarSmall: response.data.data[0].imageUrlSmallUrl})
      // if (upload) {
      //   // console.log(`upload.response=${JSON.stringify(upload)}`)
      //   await fetchData();
      // }
      const imageUrlBigUrl = response.data.data[0].imageUrlBigUrl
      if (isReseta) {
        setFileReseta(imageUrlBigUrl)
      }
      else {
        setFileOrderlist(imageUrlBigUrl)
      }
      setShowImage(true)
      setShowMayWe(false)
      _onSendSocket(selectedData.id, "sendFile", `${isReseta ? 1 : 0},${imageUrlBigUrl}`);
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = (event, isReseta) => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded, isReseta);
    }
  };

  const handleClickReseta = event => {
    hiddenFileInputReseta.current.click();
  };

  const handleClickOrderlist = event => {
    hiddenFileInputOrderlist.current.click();
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      {(level == 0 && dataLoaded && selectedData.length == 0) ?
        <div className={classes.homeContainer} >
          <div style={{ width: '100%', alignItems: 'center', borderRadius: 20, justifyContent: 'center', minWidth: 300, maxWidth: 300, marginTop: 70, border: 'solid 2px #dc2828' }}>
            <>
            <div style={{backgroundColor: 'transparent', display: "flex", width: '100%', minHeight: 450, 
              top: 120, alignItems: 'center', justifyContent: 'center'}}>
              <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 20}}>All agents are busy right now</p>
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20, fontStyle: 'italic'}}>Please try again later</p>
              </div>
            </div>  
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={_endCall} title="" style={{position: 'absolute'}}
            />
          </div>
        </div> :
        <div className={classes.homeContainer} >
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 300, maxWidth: 300, marginTop: 70, border: 'solid 2px #dc2828' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', marginTop: 0, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <OTSession apiKey={apiKey} sessionId={sessionId} token={token} audioTrack={true} publishAudio={true} publishVideo={true} videoTrack={true}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w, width: w, marginTop: -1, top: 0,
                  borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                >
                  <OTStreams>
                    <OTSubscriber  properties={{ insertMode: "append", width: w-2, height: w }}/>
                  </OTStreams>
                  {selectedData && <div style={{display: 'flex', backgroundColor: '#00000090', width: w-2, position: 'absolute', top: w+12, justifyContent: 'center', alignItems: 'center', height: 60}}>
                      <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 20, height: 15}}>{selectedData.name}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'white', fontSize: 16, fontStyle: 'italic', marginBottom: 10}}>{selectedData.position}</p>
                      </div>
                    </div>
                  }
                </div>
                <div style={{display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: 0,  bottom: 300, marginLeft: 1, borderTopRightRadius: 0, overflow: 'hidden'}}>
                  <OTPublisher properties={{
                      publishAudio: isAudioEnabled,
                      publishVideo: isVideoEnabled,
                      width: 100, height: 100,
                    }}
                  />
                </div>
              </OTSession>
            </div>
            </>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: w * 0.85, width: w, marginTop: -100, top: 0,
              borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
            >
              {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 18, position: 'absolute', bottom: h * 0.26}}>{textToShow}</p>}
              {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.18}}>{subTextToShow}</p>}
              {showMayWe && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.31}}>May we take</p>}
              {showMayWe && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.23}}>your order please?</p>}
              {showImage && <div style={{position: 'absolute', bottom: h * 0.16, width: w * 0.55, maxHeight: w * 0.5, overflowX: 'hidden', overflowY: 'scroll'}}>
                  {fileReseta && <img src={fileReseta} style={{width: w * 0.5, height: w * 0.5, }} />}
                  {fileOrderlist && <img src={fileOrderlist} style={{width: w * 0.5, height: w * 0.5}} />}    
                </div>                    
              }
              {(showMayWe || showImage) && <div style={{position: 'absolute', bottom: h * 0.05, left: isAndroid ? 45 : 38, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickReseta() : {}} >
                  <img src={enableCapture ? Prescription : PrescriptionGray} style={{width: 40, height: 40}} />
                </div>
              }
              {(showMayWe || showImage) && <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    ref={hiddenFileInputReseta}
                    onChange={(event) => handleChange(event, true)}
                    style={{display: 'none'}} // Make the file input element invisible 
                  />
                </div>
              }
              {(showMayWe || showImage) && <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    ref={hiddenFileInputOrderlist}
                    onChange={(event) => handleChange(event, false)}
                    style={{display: 'none'}} // Make the file input element invisible 
                  />
                </div>
              }
              {(showMayWe || showImage) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, left: isAndroid ? 45 : 33, width: 40, textAlign: 'center'}}>Prescription</p>}
              {(showMayWe || showImage) && <div style={{position: 'absolute', bottom: h * 0.05, right: isAndroid ? 50 : 40, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickOrderlist() : {}} >
                  <img src={enableCapture ? Orderlist : OrderlistGray} style={{width: 40, height: 40}} />
                </div>
              }
              {(showMayWe || showImage) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, right: isAndroid ? 48 : 38, width: 50, textAlign: 'center'}}>Order List</p>}
              {showDownload && !showText && <a style={{textAlign: 'center', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer', position: 'absolute', bottom: h * 0.34}}>Download the RxClub App</a>}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.22}}>to get additional ₱300</p>}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.14}}>Promo Code</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 22, position: 'absolute', bottom: h * 0.36}}>PROMO CODE STEPS:</p>}
              {showDownload2 && !showText && <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', bottom: h * 0.28, width: w * 0.8}}>
                  <p style={{textAlign: 'left', fontWeight: '500', width: 25, color: '#404040', fontSize: 22}}>1.</p>
                  <a style={{textAlign: 'left', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer'}}>Download app</a>
                </div>
              }
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.21}}>2. Input phone number</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.65, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.15}}>and OTP</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.08}}>3. Get Promo Code</p>}
              {!showText && showNewCart && !showFinalCost && !showDiscount && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    {sku.length > 0 && <div
                        style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                      >
                        <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                          <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                              <div style={{minHeight: 20}}>
                                { sku.map((item,index) => renderItem(item, index)) }
                              </div>
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(sku.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                              </div>
                          </div> 
                        </div>   
                      </div> 
                    }
                  </div>      
                </div>
              }
              {!showText && showUserDetails && !showNewCart && !showFinalCost && !showDiscount && <div style={{marginTop: 10, marginLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.firstname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.lastname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.phoneNumber}</p>
                    </div>
                    {userData.address && userData.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.address}</p>
                      </div>
                    }
                    { userData.scPwdId &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>SC/PWD ID#:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.scPwdId}</p>
                      </div>
                    }
                    {typeof userData.Referror !== 'undefined' && userData.Referror &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                      </div>
                    }
                  </div>
                </div>
              }
              {!showText && showDiscount && !showNewCart && !showFinalCost && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                        <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                        <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                      </div>
                      <div style={{marginTop: 40}}>
                        {discountTypesData.map((item, index) => {
                          return <>
                          { item.name.toUpperCase().includes('SENIOR') &&
                            <div style={{height: 20}} />
                          }
                          <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                            { item.is_percentage == 1 && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                            { item.is_percentage == 0 && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                            <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                          </div>
                          {/* { index < discountTypesData.length - 1 &&
                            <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                            </div>
                          } */}
                          { item.name.toUpperCase().includes('REFERRAL') &&
                            <div style={{height: 20}} />
                          }
                          </>
                        })}
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              }
              {!showSms && !showText && showFinalCost && !showNewCart && !showUserDetails && !showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                          { selectedDs.details.map((item,index) => renderItem(item, index)) }
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                            <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                            <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                          </div>
                          {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, fontStyle: 'italic', color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                            </div>
                          }
                          {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 10}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                              <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#55b88a', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                            </div>
                          }
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                            <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                            <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                          </div>
                          <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 20, marginTop: 30}} >{selectedDs.name}</p>
                          </div>
                      </div> 
                    </div>  
                  </div>
                </div>
              }
              {!showSms && !showText && showConfirm && alreadyBought && !showFinalCost && !showNewCart && !showUserDetails && !showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05, marginBottom: 30}}>
                      <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 40, height: 30}}>You saved</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 54, height: 54}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18}}>in your order</p>
                      </div> 
                      <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18, height: 14}}>Your Total Savings in RxClub:</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 28, height: 28}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                      </div> 
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 60, width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Name: </p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.firstname} {userData.lastname}</p>
                      </div> 
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Address:</p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.address}</p>
                      </div>
                      {typeof userData.Referror !== 'undefined' && userData.Referror &&
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Referror:</p>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                        </div>
                      }
                    </div> 
                  </div>
                </div>
              }
              {showSms && <>
                  <div style={{width: w * 0.95, minHeight: height * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>Please wait for the</p>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>SMS link</p>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>to track your order.</p>
                    </div> 
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 20}}>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 24, marginBottom: 10}}>Thank you!</p>
                    </div> 
                  </div> 
                </>
              }
            </div>
          </div>
          {showPreloader && <div className={classes.preloaderContainer} style={{backgroundColor: 'white'}}>
              <div style={{height, width, flex: 1, alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <div style={{backgroundColor: '#ffcaca', borderRadius: 20, display: "flex", width: w, height: h, position: 'absolute', 
                    top: 40, alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={"https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1668333327614.jpg"} style={{width: w * 0.9, height: w * 0.9 * 647 / 405}}/>
                  </div>
                </div> 
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 100, marginRight: 40}}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent'}}>
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20, marginTop: h + 60, height: 20}}>Connecting your</p>
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20}}>RX Pharmacist</p>
                  </div>
                  <div style={{width: 48, height: 48, position: 'absolute', bottom: 120, right: 30}}>
                    <CircularProgressbarWithChildren
                      value={percentage}
                      strokeWidth={16}
                      styles={buildStyles({
                        pathColor: "#dc2828",
                        trailColor: "transparent"
                      })}
                    >
                      {/*
                        Width here needs to be (100 - 2 * strokeWidth)% 
                        in order to fit exactly inside the outer progressbar.
                      */}
                      <div style={{ width: "75%", position: 'absolute' }}>
                        <CircularProgressbar
                          counterClockwise
                          value={percentage}
                          strokeWidth={14}
                          styles={buildStyles({
                            rotation: 3 / 4 + 1 / 8,
                            pathColor: "#55b88a",
                            trailColor: "transparent"
                          })}
                        />
                      </div>
                      <div style={{ width: "55%", position: 'absolute' }}>
                        <CircularProgressbar
                          value={percentage}
                          strokeWidth={12}
                          styles={buildStyles({
                            rotation: 1 / 2,
                            pathColor: "#ffcaca",
                            trailColor: "transparent"
                          })}
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                  {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 100}}>
                  <div style={{width: 12, height: 12}}>
                    <CircularProgressbar
                      value={percentage * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: "#dc2828",
                        trailColor: "#ffcaca"
                      })}
                    />
                  </div>
                  <div style={{width: 14, height: 14, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -20) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 20 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 20 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 16, height: 16, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -40) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 40 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 40 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 18, height: 18, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -60) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 60 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 60 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 20, height: 20, marginLeft: 15}}>
                    <CircularProgressbar
                      value={((percentage - 80) * 5)}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 80 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 80 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div> 
                </div>*/}
              </div>
            </div>
          }
          <p style={{textAlign: 'left', fontWeight: '500', width: w, color: '#404040', fontSize: 12, marginTop: 5, height: 12, textAlign: 'center', position: 'absolute', bottom: -30}} >v{packageJson.version}</p>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={_endCall} title="" style={{position: 'absolute'}}
              // rightButtonLabel="INPUT"
              // onRightPress={() => navigate(routes.cartInput, { state: {userId} })}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Tumawag;