import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/placeHolder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Save_1.png';
// import Header2 from '../../../assets/images/header-images/Save_2.png';
import innerGo from '../../../assets/images/common-images/go.png';
import Kailan from '../../../assets/images/common-images/kailan.png';
import rxPlus from '../../../assets/images/common-images/plusIcon.png';
import rxMinus from '../../../assets/images/common-images/minusIcon.png';
import rxSavingsTagRed from '../../../assets/images/common-images/savingsTagRed.png';
import Okay from '../../../assets/images/common-images/okay.png'
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';
import {formatNumberWithComma} from '../../../utils/utils';
import Moment from 'moment';
import SwipeToDelete from 'react-swipe-to-delete-component';
// import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';
import "./swipe-to-delete.css"
import { io } from "socket.io-client";
import packageJson from '../../../../package.json';

import {
  getDelivery, getDeliveryHistory, setDeliveryStatus
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import MyButton from '../../../Components/Common/MyButton';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const Order = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [reseta, setReseta] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dayId, setDayId] = useState(7);
  const [level, setLevel] = useState(0);
  const [ds, setDs] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [selectedDs, setSelectedDs] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(50);
  const [taxPercentage, setTaxPercentage] = useState(0.1);
  const [userData, setUserData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const props = useLocation().state;
  
  const fetchData = async () => {
    try {
      setLoader(true);
      if (props !== null) {
        if (typeof props.tabId !== 'undefined') {
          const status = _getStatus();
          if (props.tabId == ids.channelBattle) {
            const response = await getDeliveryHistory({status});
            console.log(`response=${JSON.stringify(response.data.data)}`);
            if (response) {
              setData(response.data.data);
            }
          }
          else {
            const response = await getDelivery({status});
            console.log(`response=${JSON.stringify(response.data.data)}`);
            if (response) {
              setData(response.data.data);
            }
          }
        }
      }
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  const _getStatus = () => {
    let status = -1
    switch (props.tabId) {
      case ids.channelJackpot:
        status = 0;
        break;
      case ids.channelTourney:
        status = 1;
        break;
      case ids.channelBattle:
        status = 2;
        break;  
      default:
        break;
    }
    console.log(`status=${status}`)
    return status;
  }

  useEffect(() => {
    console.log(`here`)
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const renderItem = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.65}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  const w = 340;
  const h = 370;

  const _getButtonLabel = () => {
    switch(props.tabId) {
      case ids.channelJackpot:
        return 'Receive';
      case ids.channelTourney:
        return 'Done'; 
      case ids.channelBattle:
        return 'Back';
      default:
        return '';
    }
  }

  const _onButtonPress = () => {
    if (data.length > 0) {
      switch(props.tabId) {
        case ids.channelJackpot:
          _setDeliveryStatus(ids.deliveryStatusPurchaserReceived);
          break;
        case ids.channelTourney:
          _setDeliveryStatus(ids.deliveryStatusPurchaserBought);
          break;
        case ids.channelBattle:
          break;
        default:
          break;
      }
    }
    navigate(-1);
  }

  const _setDeliveryStatus = (status) => {
    setDeliveryStatus({deliveryId: data[0].id, status})
    .then(({data}) => {
    })
    .catch(e => {
        console.warn('set delivery status ERROR', e.message);
    });
  }

  return (
    <div className={classes.cartContainer}>
      {loader && <Loader />}
      {level == 0 && data.length > 0 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 350}}>
              <div style={{display: 'flex', flexDirection: 'row', maxWidth: w, justifyContent: 'flex-start', alignItems: 'flex-start', overflowY: 'hidden', overflowX: 'hidden', minHeight: h, backgroundColor: 'transparent'}} >
                {data.map((item, index) => {
                    return <div style={{display: 'flex', flexDirection: 'column', width: 350, alignItems: 'center', justifyContent: 'center',}}>
                      <div style={{ display: "flex", width: w, backgroundColor: '#ffcaca', marginBottom: 20, alignItems: 'center', flexDirection: 'column', height: 120, borderRadius: 20, justifyContent: 'center' }}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, alignItems: 'center', marginTop: 5}}>
                          <p style={{left: 10, color: '#dc2828', fontSize: 17, textAlign: 'left', fontWeight: '500'}} >Drugstore:</p>
                          <p style={{fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#404040', textAlign: 'right'}}>{item.drugstore.name}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, alignItems: 'center'}}>
                          <p style={{left: 10, color: '#dc2828', fontSize: 17, textAlign: 'left', fontWeight: '500'}} >Member:</p>
                          <p style={{fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#404040', textAlign: 'right'}}>{item.user.firstName} {item.user.lastName}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, alignItems: 'center'}}>
                          <p style={{left: 10, color: '#dc2828', fontSize: 17, textAlign: 'left', fontWeight: '500'}} >Date:</p>
                          <p style={{fontWeight: '500', fontSize: 20, marginLeft: 5, color: '#404040', textAlign: 'right'}}>{Moment(item.boughtDate).format("MMM DD, YYYY h:mmA")}</p>
                        </div>
                      </div>
                      <div
                        style={{width: w, minHeight: h, backgroundColor: '#ffcaca', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                      >
                        <div style={{display: "flex", width: w, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: h}}>
                          <div style={{marginTop: 20, width: w, alignItems: 'center', marginLeft: w * 0.05}}>
                              <div style={{minHeight: 200}}>
                                { item.details.map((item,index) => renderItem(item, index)) }
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                                <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                                <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.total,2)}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                                <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                                <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, fontStyle: 'italic', color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-item.totalDiscount,2)}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 10}}>
                                <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                                <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#55b88a', textAlign: 'right'}}>₱{formatNumberWithComma(item.total-item.totalDiscount,2)}</p>
                              </div>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                                <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE:</p>
                                <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.deliveryFee,2)}</p>
                              </div>
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((item.total - item.totalDiscount) + Number(item.deliveryFee),2)}</p>
                              </div>
                          </div> 
                        </div>   
                      </div>
                      <div 
                        style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 0}}
                      >
                        <MyButton label={_getButtonLabel()} onPress={() => _onButtonPress()}/>
                      </div>
                    </div>
                  }) 
                }
              </div>      
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            {/* { props.userId  ? <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}/> :
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}
                rightButtonLabel="Add Item"
                onRightPress={() => setLevel(12)}
              /> 
            } */}
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
    </div>
  );
};

export default Order;
